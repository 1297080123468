<!--门禁模块--车场设置-->
<template>
  <div
    id="parkingLotSet"
    v-loading="loading"
    :element-loading-text="`${$t('parkingLotSet.a1')}....`"
  >
    <div class="header">
      <my-headertitle>{{ $t("parkingLotSet.a2") }}</my-headertitle>
    </div>
    <div class="main">
      <div class="card-container">
        <a-tabs type="card" style="height:100%;">
            <!-- 车场配置信息 -->
          <a-tab-pane key="1" :tab="$t('eqct.a1')">
             
             <a-row class="main-content-api-row">
                  <a-col :span="8"  class="main-content-api-col">
                      <div class="main-content-box" >
                          <div class="main-content-title dis-between">
                              <!-- 接口信息 -->
                              <span>{{$t('eqct.a5')}}</span>
                              <a-button type="primary" @click="loadEndEquipmentList(1)">{{$t('energy.search')}}</a-button>
                          </div>
                          <div class="main-content-form-area">
                                <a-form-model
                                  ref="carRuleForm"
                                  :rules="carRules"
                                  :model="carform"
                                  :label-col="labelCol"
                                  :wrapper-col="wrapperCol">
                                  <!-- 终端品牌 -->
                                    <a-form-model-item ref="endBrand" :label="$t('eqct.a6')" prop="endBrand" >
                                        <a-select v-model="carform.endBrand" @change="carEndBrandChange">
                                          <a-select-option v-for="(item,index) in endBrandList"  :key="index" :value="item.no">{{item.name}}</a-select-option>
                                      </a-select>
                                  </a-form-model-item>
                                  <!-- 用户名 -->
                                  <a-form-model-item ref="apiAccount" :label="$t('eqct.a7')" prop="apiAccount" >
                                      <a-input v-model="carform.apiAccount" />
                                  </a-form-model-item>
                                  <!-- 密码 -->
                                    <a-form-model-item ref="apiPassword" :label="$t('eqct.a8')" prop="apiPassword" >
                                      <a-input v-model="carform.apiPassword" />
                                  </a-form-model-item>
                                  <!-- API路径 -->
                                  <a-form-model-item ref="apiURL" :label="$t('eqct.a9')" prop="apiURL" >
                                      <a-input v-model="carform.apiURL" />
                                  </a-form-model-item>
                                  <!-- 接口版本 -->
                                  <a-form-model-item ref="version" :label="$t('eqct.a10')" prop="version" >
                                      <a-input v-model="carform.version" />
                                  </a-form-model-item>
                                  <!-- 同步访问 -->
                                   <a-form-model-item ref="appSync" :label="$t('eqct.a11')" prop="appSync" >
                                        <a-checkbox v-model="carform.appSync">{{$t('energy.enedata593')}}</a-checkbox>
                                    </a-form-model-item>

                                    <a-form-model-item ref="appID" label="APP ID" prop="appID" >
                                      <a-input v-model="carform.appID" />
                                  </a-form-model-item>
                                  <a-form-model-item ref="appKey" label="APP Key" prop="appKey" >
                                      <a-input v-model="carform.appKey" />
                                  </a-form-model-item>
                                  <a-form-model-item label="" style="text-align: right;">
                                      <a-button type="primary" @click="handleSubmit(1)">{{$t('energy.ok')}}</a-button>
                                  </a-form-model-item>
                                    
                                </a-form-model>
                              
                          </div>
                      </div>
                  </a-col>
                  <a-col :span="16" class="main-content-api-col">
                     <div class="main-content-box" >
                          <div class="main-content-title dis-between">
                              <!-- 设备信息 -->
                              <span>{{$t('eqct.a14')}}</span>
                          </div>
                          <div class="main-content-table-area">
                              <a-table
                                  :columns="carYardDeviceColumns"
                                  :data-source="carYardDevices"
                                  :pagination="false"
                                  :scroll="size"
                                  size="middle"
                                  id="equipmentTable"
                                  ref="equipmentTable"
                                  :rowKey="(record) => record.deviceId"
                              >
                                 
                              </a-table>
                          </div>
                     </div>
                      
                      
                  </a-col>
              </a-row>
          </a-tab-pane>
          <!-- 设备映射关系设置 -->
          <a-tab-pane key="2" :tab="$t('eqct.a2')">
              <div class="main-content-area">
                    <div style="padding:5px;" class="dis-between">
                        <a-button type="primary" @click="chooicEnd">{{chooicEndTitle}}</a-button>
                        
                        <a-button type="primary" @click="loadEndEquipmentList(1)" >{{$t('energy.search')}}</a-button>
                    </div>
                    <a-table
                        :columns="columns"
                        :row-selection="rowSelection"
                        :data-source="equipmentList"
                        :pagination="pagination"
                        :scroll="size"
                        size="middle"
                        id="equipmentMapTable"
                        ref="equipmentMapTable"
                    >
                      <!-- :row-key="record => record.id"  -->
                      <template slot="opeat" slot-scope="text, record">
                          <a-button
                              type="primary"
                              @click="remove(record)"
                              >{{$t('eqct.a24')}}</a-button
                              >
                      </template>
                      
                      <template slot="carYardDeviceGuId" slot-scope="text, record">
                          <a-tooltip placement="right"  :title="record.fdbmsg"  :visible="record.fdbvaild">
                              <a-select v-model="record.carYardDeviceGuId" style="width:200px;" :class="record.fdbcls">
                                  <a-select-option v-for="(item,index) in carYardDevices" :key="index"  :value="item.deviceGuid">{{item.deviceName}}</a-select-option>
                              </a-select>
                          </a-tooltip>
                      </template>
                    </a-table>
                  <div class="main-content-area-fooler dis-between">
                      <div>
                          <!-- 全部移除 -->
                          <a-button type="primary" @click="removeAll" :disabled="equipmentList.length===0">{{$t('eqct.a25')}}</a-button>
                          <a-divider type="vertical" />
                          <a-button type="primary" @click="removeSelectedRows" :disabled="selectedRows.length===0">{{$t('eqct.a24')}}</a-button>   
                          <!-- <a-divider type="vertical" />
                          <a-button type="primary" @click="clearTooltips" :disabled="selectedRows.length===0">清除提示</a-button> -->
                      </div>
                      <div>
                          <a-button type="primary" @click="handleSubmit(2)">{{$t('energy.ok')}}</a-button>
                      </div>
                  </div>
              </div>
          </a-tab-pane>
          <!-- 设备区域信息 -->
          <a-tab-pane key="3" :tab="$t('eqct.a3')">
              <div class="main-content-area">
                    
                  <div style="padding:5px;" class="dis-between">
                      <!-- 区域初始化 -->
                      <a-button type="primary" @click="carAreaInit" :disabled="equipmentList.length===0">{{$t('eqct.a27')}}</a-button>
                      <a-button type="primary" @click="loadEndEquipmentList(1)" >{{$t('energy.search')}}</a-button>
                  </div>
                  <a-table 
                    :columns="carAreaColumns" 
                    :data-source="carAreaData"
                    size="middle">
                    
                        <span slot="list" slot-scope="list">
                            <a-tag
                                v-for="tag in list"
                                :key="tag.no"
                                color="volcano"
                            >
                                {{ tag.name }}
                            </a-tag>
                        </span>
                  
                  </a-table>
              </div>
          </a-tab-pane>
          <!-- 车场人员组织架构 -->
          <a-tab-pane key="4" :tab="$t('eqct.a4')">
              <div class="main-content-area">
                <a-row class="main-content-row">
                    <a-col :span="12"  class="main-content-col" >
                        <div class="main-content-box" >
                            <!-- 本地人员组织架构信息 -->
                            <div class="main-content-title">{{$t('eqct.a30')}}</div>
                            <div class="main-content-tree-area">
                                <a-tree
                                  :selectedKeys="selectedKeys"
                                  :show-line="true"
                                  :default-expand-all="true"
                                  :tree-data="treeData"
                                  :replace-fields="replaceFields"
                                  @select="nodeSelect"
                                />
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="12"  class="main-content-col">
                        <div class="main-content-box" >
                            <!-- 车场人员组织架构信息 -->
                            <div class="main-content-title">{{$t('eqct.a31')}}</div>
                            <div class="main-content-tree-area">
                                <a-tree
                                  :show-line="true"
                                  :default-expand-all="true"
                                  :tree-data="depts"
                                  :replace-fields="replaceFields2"
                                  @select="deptNodeSelect"
                                />

                            </div>
                        </div>
                    </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24" style="margin:5px;">
                   <!--  -->
                    <a-button type="primary" @click="personnelPractice(1)" :disabled="personGrId === undefined">{{    $t("parkingLotSet.a5") }}</a-button>
                    <a-divider type="vertical" />
                    <a-button type="primary" @click="personnelPractice(2)" :disabled="personGrId === undefined || deptId === undefined ">{{  $t("parkingLotSet.a6") }}</a-button>
                    <a-divider type="vertical" />
                    <a-button type="primary" @click="loadPersonGrData()" >{{$t('energy.enedata128')}}</a-button>
                  </a-col>
                </a-row>
              </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      
    </div>
     <end-td :title="chooicEndTitle" :basic="chooicBasic"/>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import endTableDialog from "./components/endTableDialog";
import { getPersonGrData, personnelPractice, getConvertedEquipmentList,saveEquipmentConvert,removeEquipmentConvert,carParkAreaInit,getParkDeptData,remoceCarParkAreaData } from "../../api/door";


export default {
  name: "parkingLotSet",
  data() {
    let normalString = (rule, value, callback) => {
        let regEn = /[`~!@$%^&*+<>?"{},\/;'[\]]/im;
    
            if(regEn.test(value)) {
                callback(new Error(this.$t('energy.enedata218')));
            } else {
                callback();
            }
        };
         let checkPending;
         let checkEndBrand = (rule, value, callback) => {
            clearTimeout(checkPending);
           
            checkPending = setTimeout(() => {
                
                if (value < 1) {
                    callback(new Error(this.$t('eqct.a55')));//请选择终端品牌。
                } else {
                    callback();
                }
                
            }, 100); 
        };
    return {
      conDeviceType:4,
      that: this,
      treeData: undefined,
      loading: false,
      replaceFields: { title: "name", children: "children", key: "id" },
      replaceFields2: { title: "name", children: "children", key: "no" },
      personGrId: undefined,
      deptId: undefined,
      endBrandList:[],
      endBrand:'',
      carRules:{
            apiAccount:     [{ required: true, message: this.$t('eqct.a52'),//'请输入第三方接口账户。',
                                    trigger: 'blur' },
                                { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                { validator:normalString, trigger: 'blur' },
                            ],
            apiPassword:    [{ required: true, message: this.$t('eqct.a53'),//'请输入第三方接口账户密码。',
                                trigger: 'blur' },
                                { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                { validator:normalString, trigger: 'blur' },
                            ],
            apiURL:         [{ required: true, message:this.$t('eqct.a54'),// '请输入第三方接口地址。', 
                                trigger: 'blur' },
                                { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                            //  { validator:normalString, trigger: 'blur' },
                            ],
            appID:          [,
                                { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                { validator:normalString, trigger: 'blur' },
                            ],
            appKey:         [,
                                { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                                { validator:normalString, trigger: 'blur' },
                            ],
            appSync:        [],
            endBrand:       [{ required: true, message:this.$t('eqct.a55'),// '请选择终端品牌。',
                                    trigger: 'change' },{ validator: checkEndBrand, trigger: 'change' }],
            version:        [{ required: true, message: this.$t('eqct.a56'),//'请输入第三方接口版本。', 
                        trigger: 'blur' },],
        },
      carform:{
          apiAccount:'',
          apiPassword:'',
          apiURL:'',
          appID:'',
          appKey:'',
          appSync:false,
          endBrand:0,
          version:'1',
      },
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      carAreaColumns:[
          {
              title: 'No.',
              dataIndex: 'no',
              key: 'no',
          },
          {
              title:this.$t('eqct.a28'),//'区域名称',
              dataIndex: 'name',
              key: 'name',
          },
          {
              title: this.$t('eqct.a29'),//'设备列表',
              key: 'list',
              dataIndex: 'list',
              scopedSlots: { customRender: 'list' },
          }
      ],
      carAreaData:[],
      columns:[
          {
              id:"1",
              title: "NO.",
              dataIndex:"NO",
              customRender: (text, record, index) =>
                  `${
                  (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                  index +
                  1
                  }`,
              align: "center",
              width: 60,
          },
          {
              title: this.$t('eqct.a21'),//'关联终端类型',
              dataIndex: 'memberTypeLabel',
              // width: 85,
              // fixed: "left",
          },
          {
              title: this.$t('eqct.a22'),//'设备/终端名称',
              dataIndex: 'doorName',
              // width: 85,
              // fixed: "left",
          },
            {
              title: this.$t('eqct.a23'),//'关联道闸终端',
              dataIndex: 'carYardDeviceGuId',
              scopedSlots: { customRender: "carYardDeviceGuId" },
              // width: 85,
              // fixed: "left",
          },
          {
              title: this.$t('visitorBookingRecord.a48'),//'操作',
              dataIndex: 'opeat',
              scopedSlots: { customRender: "opeat" }
          }
      ],
      pagination: {
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => `${total} ${this.$t("attendance.a11")}`, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
          },
      },
      equipmentList:[],
      selectedRowKeys:[],
      selectedRows:[],
      checkType:'checkbox',
      size:{y:400},
      carYardDevices:[],
      carYardDeviceColumns:[
          {
              title:this.$t('eqct.a15'),//'设备编号',
              dataIndex: 'deviceId',
              key: 'deviceId',
          },
          {
              title:this.$t('eqct.a16'),//'设备名称',
              dataIndex: 'deviceName',
              key: 'deviceName',
          },
          {
              title:this.$t('eqct.a17'),//'设备IP',
              dataIndex: 'deviceIp',
              key: 'deviceIp',
          },
          {
              title:this.$t('eqct.a18'),//'设备网关IP',
              dataIndex: 'deviceGateway',
              key: 'deviceGateway',
          },
          {
              title:this.$t('eqct.a19'),//'设备子网掩码',
              dataIndex: 'deviceNetMask',
              key: 'deviceNetMask',
          },
      ],
      chooicEndTitle:this.$t('eqct.a20'),//'选择门',
      chooicBasic:{
          action:1,
          clientId:this.$store.getters.clientId,
          sitegrId:this.$store.getters.sitegrId,
          siteId:this.$route.query.id,
          tenantId: 0,
          ends:[]
      },
      chooicEndAction:0,
      endTableDialogVisible:false,
      depts:[],
      selectedKeys:[],
    };
  },
  filters: {
    nothing: (value, that) => {
      if (value == null || value == "") {
        return (value = that.$t("parkingLotSet.a7"));
      } else {
        return value;
      }
    },
  },
  mounted() {
    // 进入页面获取信息
   
    this.loadEndEquipmentList(0);
    
  },
  methods: {
      chooicEnd(){          
          this.chooicBasic.ends = this.equipmentList;
          this.endTableDialogVisible = true;
      },
      loadPersonGrData(){
        this.selectedKeys=[];
        this.deptId = undefined;
        this.personGrId = undefined;
        const data = {
            action: 0,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
        };
        console.log(data);
        this.loading = true;
        getPersonGrData(data)
          .then((res) => {
            console.log(res);
            const { data } = res;
            // this.treeData.push(data);
            data.children[0].selectable = false;
            data.children[0].checkable = false;
            this.treeData = data.children;
            this.loadParkDeptData();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      },
      loadParkDeptData(){
        let param = {
              action: 0,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              iconDeviceType: this.conDeviceType,
          }
          this.loading = true;
          console.log("loadParkDeptData param>>>",param);
          getParkDeptData(param)
          .then((res) => {
              console.log("loadParkDeptData List>>>",res);
              this.depts = [];
              if(res.data.deptTree){
                  this.depts.push(res.data.deptTree);
              }
          })
          .catch((err) => {
              this.loading = false;
              console.log(err);
          });
        
      },

       loadEndEquipmentList(action){
          this.$refs.carRuleForm.resetFields();
          let param = {
              action: action,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              iconDeviceType: this.conDeviceType,
          }
          this.loading = true;
          this.equipmentList = [];
          console.log("Load Converted Equipment param>>>",param);
          getConvertedEquipmentList(param)
          .then((res) => {
              console.log("Load Converted Equipment List>>>",res);
              if(action===0){
                this.equipmentCotegories = res.data.tabData;
                this.equipmentCotegoryMap = new Map();
                for (let i = 0; i < this.equipmentCotegories.length; i++) {
                    let element = this.equipmentCotegories[i];

                    this.equipmentCotegoryMap.set(element.no,element.children);
                }
                this.endBrandList = this.equipmentCotegoryMap.get(this.conDeviceType);
                this.endBrand = '';
                this.loadEndEquipmentList(1);
              } else if(action===1){
                this.endBrand = res.data.brand;
                this.carform.endBrand       = res.data.brand;
                

                
                this.carAreaData = res.data.carYardAreas;
            
                if(res.data.carYardDevices){
                    this.carYardDevices = res.data.carYardDevices;
                }
                if(res.data.carYardInfo){
                    
                    this.carform.apiAccount		= res.data.carYardInfo.username;
                    this.carform.apiPassword	= res.data.carYardInfo.password;
                    this.carform.apiURL			= res.data.carYardInfo.url;
                    this.carform.appID			= res.data.carYardInfo.appId;
                    this.carform.appKey			= res.data.carYardInfo.key;
                    this.carform.appSync		= res.data.carYardInfo.issend;
                    this.carform.version        = res.data.carYardInfo.version;
                }
                
                this.equipmentList = res.data.data;
                this.equipmentList.forEach(row => {
                    row.eqncls = "";
                    row.eqnvaild = false;
                    row.eqnmsg = '';
                });

                 this.loading = false;
                 this.loadPersonGrData();
              }
              
             
          })
          .catch((err) => {
              this.loading = false;
              console.log(err);
          });
      },
     handleSubmit(tag){
       if(tag===1){
          this.$refs.carRuleForm.validate(valid => {
              if (valid) {
                let param = {
                    action: 1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    iconDeviceType: this.conDeviceType,
                    equipments:this.equipmentList,
                    update:1,
                    brand:this.endBrand,
                    apiAccount:this.carform.apiAccount,
                    apiPassword:this.carform.apiPassword,
                    apiURL:this.carform.apiURL,
                    appID:this.carform.appID,
                    appKey:this.carform.appKey,
                    appSync:this.carform.appSync,
                    version:this.carform.version,
                };
                this.saveEnd(param);
              } else {
                  console.log('error submit!!');
              }
          });
       } else if(tag===2){
         let vail = false;
         let arr = [];
          
          for (let i = 0; i < this.equipmentList.length; i++) {
              const row = this.equipmentList[i];
              
              if(this.conDeviceType===1){
                  let evail1 =  this.check('',row.faceName,1,50);
                  if(evail1.err===0){
                      row.fcncls = "";
                      row.fcnvaild = false;
                      row.fcnmsg = '';    
                  }else{
                      row.fcncls     = evail1.cls;
                      row.fcnvaild   = evail1.visible;  
                      row.fcnmsg     = evail1.title;   
                      vail = true;
                  }
                  let evail2 =  this.check('',row.faceDeviceKey,1,20);
                  if(evail2.err===0){
                      row.fdkcls = "";
                      row.fdkvaild = false;
                      row.fdkmsg = '';    
                  }else{
                      row.fdkcls     = evail2.cls;
                      row.fdkvaild   = evail2.visible;  
                      row.fdkmsg     = evail2.title;   
                      vail = true;
                  }
                  let evail3 =  this.check('',row.faceDeviceIp,1,20);
                  
                  if(evail3.err===0){
                      row.fdpcls = "";
                      row.fdpvaild = false;
                      row.fdpmsg = '';    
                  }else{
                      row.fdpcls     = evail3.cls;
                      row.fdpvaild   = evail3.visible;  
                      row.fdpmsg     = evail3.title;   
                      vail = true;
                  }

                  let evail4 =  this.check('',row.faceDeviceBrand,1,10);
                  if(evail4.err===0){
                      row.fdbcls = "";
                      row.fdbvaild = false;
                      row.fdbmsg = '';    
                  }else{
                      row.fdbcls     = evail4.cls;
                      row.fdbvaild   = evail4.visible;  
                      row.fdbmsg     = evail4.title;   
                      vail = true;
                  }
              } else {
                  let evail =  this.check('',row.equipmentName,1,50);
                  if(evail.err===0){
                      row.eqncls = "";
                      row.eqnvaild = false;      
                      row.eqnmsg = '';              
                  }else{
                      row.eqncls = evail.cls;
                      row.eqnvaild = evail.visible;  
                      row.eqnmsg = evail.title;   
                      vail = true;
                  }
              }
              console.log("equipment>>",row);
              arr.push(row);
          }

          this.equipmentList = [...arr];
          if(vail){
              return;
          }
          let param = {
              action: 1,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              iconDeviceType: this.conDeviceType,
              equipments:this.equipmentList,
              update:2,
              brand:this.endBrand,
              apiAccount:this.carform.apiAccount,
              apiPassword:this.carform.apiPassword,
              apiURL:this.carform.apiURL,
              appID:this.carform.appID,
              appKey:this.carform.appKey,
              appSync:this.carform.appSync,
              version:this.carform.version,
          };
          this.saveEnd(param);
       }
        
    },
    saveEnd(param){
        console.log('saveEquipmentConvert param',param);
        this.$confirm({
            title: this.$t('eqct.a62'),//'确认要保存所设置的信息吗？',
            centered: true,
            onOk: () => {
                this.loading = true;
                saveEquipmentConvert(param).then((res)=>{
                    console.log('SaveEquipmentConvert res ',res);
                    if(res.errorCode=='00' || res.errorCode=='03'){
                        this.$message.success(res.msg);
                        this.loadEndEquipmentList(1);
                    }else{
                        this.$message.error(res.msg);
                        this.loading = false;
                    }

                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            },
        });

    },
    check(tag,value,min,max){
        let err = 0;
        let msg = '';
        let inputLabel = this.$t('energy.setting.reportGr.inputLabel');// '请输入${label}',
        let spLabel= this.$t('energy.setting.reportGr.spLabel');// '${label}不能包含特殊字符!',
        let lenLabel= this.$t('energy.setting.reportGr.lenLabel');// '${label}长度必须在 ${min} 到 ${max}之间',
        if(value===undefined || value===''){
            err = 1;
            msg = inputLabel.replace('${label}',tag);
        }

        if(err ==0 && min>0&& max>0){
            let len = value.length;
            if( len<min || len>max){
                err = 1;
                msg = lenLabel.replace('${label}',tag).replace('${min}',min).replace('${max}',max);
            }
        }

        if(err ==0){
            let bol = this.checkString(value);
            if(bol){
                err = 1;
                msg = spLabel.replace('${label}',tag);
            }
        }
    

        
        let visible = err>0;
        let cls = visible?'err':'';
        let verr = {
            cls:cls,
            title:msg,
            visible:visible,
            err:err,
        }

        return verr;
    },
    checkString(value){
        let regEn = /[`~!@$%^&*+<>?:"{},\/;'[\]]/im;
        return regEn.test(value) ;
    },
    remove(record){
        this.$confirm({
            title: this.$t('eqct.a66'),//'确认要取消该设备的转换信息吗？',
            centered: true,
            onOk: () => {
                let arr = [];
                arr.push(record);
                
                let param = {
                    action: 1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    iconDeviceType: this.conDeviceType,
                    equipments:arr,
                    
                };
                console.log('RemoveEquipmentConvert param ',param);
                removeEquipmentConvert(param)
                .then((res)=>{
                    console.log('RemoveEquipmentConvert res ',res);
                    if(res.errorCode=='00' || res.errorCode=='03'){
                        this.$message.success(res.msg);
                        this.loadEndEquipmentList(1);
                    }else{
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
            }
        });
        
    },
    removeAll(){
        this.$confirm({
            title: this.$t('eqct.a60'),//'确认要取消所有的转换信息吗？',
            centered: true,
            onOk: () => {
                
                let param = {
                    action: 1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    iconDeviceType: this.conDeviceType,
                    equipments:this.equipmentList,
                    
                };
                  console.log('RemoveEquipmentConvert param ',param);
                removeEquipmentConvert(param)
                .then((res)=>{
                    console.log('RemoveEquipmentConvert res ',res);
                    if(res.errorCode=='00' || res.errorCode=='03'){
                        this.$message.success(res.msg);
                        this.loadEndEquipmentList(1);
                    }else{
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });

            }
        });
    },
    removeSelectedRows(){
        this.$confirm({
            title:  this.$t('eqct.a61'),//'确认要取消所选择的设备的转换信息吗？',
            centered: true,
            onOk: () => {
                
                let param = {
                    action: 1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    iconDeviceType: this.conDeviceType,
                    equipments:this.selectedRows,
                };
                  console.log('RemoveEquipmentConvert param ',param);
                removeEquipmentConvert(param)
                .then((res)=>{
                    console.log('RemoveEquipmentConvert res ',res);
                    if(res.errorCode=='00' || res.errorCode=='03'){
                        this.$message.success(res.msg);
                        this.loadEndEquipmentList(1);
                        this.selectedRowKeys=[];
                        this.selectedRows=[];
                    }else{
                        this.$message.error(res.msg);
                        this.loading = false;
                    }
                }).catch((err) => {
                    this.loading = false;
                    console.log(err);
                });

            }
        });
    },
    clearTooltips(){
      
    },
    carEndBrandChange(e){
        this.endBrand = e;
    },
    carAreaInit(){
        let param = {
            action: 0,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id
        }
        this.loading = true;
        carParkAreaInit(param)
        .then((res) => {
            console.log("Load Converted Equipment List>>>",res);
            this.carAreaData = res.data.carYardAreas;
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    // 侧边点击选择id
    nodeSelect(selectedKeys, info) {
      console.log(selectedKeys,info.node.selected,info.node.dataRef);
      this.selectedKeys = selectedKeys;
      if(!info.node.selected){
        this.personGrId = info.node.dataRef.id;
        this.deptId = info.node.dataRef.itemId==null?undefined:info.node.dataRef.itemId;
      }else{
        this.personGrId = undefined;
        this.deptId = undefined;
      }
      console.log(this.personGrId,this.deptId);
      // this.
    },
    deptNodeSelect(){

    },
    personnelPractice(value) {
      if (this.personGrId == undefined) {
        this.$error({
          title: this.$t("parkingLotSet.a8"),
          content: this.$t("parkingLotSet.a9"),
          centered: true,
          okText: this.$t("parkingLotSet.a10"),
        });
      } else {
        this.$confirm({
          title: this.$t("parkingLotSet.a11"),
          centered: true,
          onOk: () => {
            this.loading = true;
            const data = {
              siteId: this.$route.query.id,
              personGrId: parseInt(this.personGrId),
              mode: value,
            };
            personnelPractice(data)
              .then((res) => {
                this.loading = false;
                const { errorCode } = res;
                const { msg } = res;
                if (errorCode == "00") {
                  this.$message.success(msg);
                } else {
                  this.$message.error(msg);
                }
                console.log(res);
                this.loadPersonGrData();
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys,selectedRows){
        this.selectedRowKeys = selectedRowKeys;
        this.selectedRows = selectedRows;
    },
  },
  computed: {
      rowSelection() {
          const { selectedRowKeys } = this;
          return {
              selectedRowKeys,
              onChange: this.onSelectChange,
              hideDefaultSelections: true,
              type:this.checkType,
          };
      },
  },
  components: {
    "my-headertitle": headerTitle,
    "end-td":endTableDialog,
  },
};
</script>
<style scoped>
#parkingLotSet {
  height: 100%;
}
.header {
  padding: 0px 20px;
}
.main {
  width: 100%;
  height: calc(100% - 51px);
  display: flex;
  overflow: auto;
}
.card-container {
  width: 100%;
  height: calc(100% - 10px);
  background: #e8e8e8;
  overflow: hidden;
  padding: 5px;
  margin: 0 20px 5px;
} 

div /deep/ .ant-tabs-content {
  height: calc(100% - 10px);
  margin-top: -16px;
}

div /deep/ .ant-tabs-tabpane {
  height:calc(100% - 10px);
  background: #fff;
  padding: 0 5px;
}

div /deep/ .ant-tabs-bar {
  border-color: #fff;
}

div /deep/ .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

div /deep/ .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

div /deep/ .ant-form-item{
  margin-bottom: 20px;
}

div /deep/ .ant-form-item-with-help {
    margin-bottom: 5px;
}
.main-content-area{
    width: 100%;
    height:100%;    
    position: relative;
}

.main-content-api-row{
    width: 100%;
    height: 100%;;
    
}
.main-content-api-col{
    height: 100%;
}

.main-content-row{
    width: 100%;
    height: calc(100% - 60px);
    
}
.main-content-col{
    height: 100%;
}

.main-content-box{
    padding: 5px;
    margin: 5px;
    width: 100%;
    height:100%;
}

.main-content-title{
    padding: 10px 5px;
    font-size: 14px;
    background-color: #e8e8e8;
}
.main-content-title span{
    margin: 5px;
}
.main-content-form-area{
  border: 1px solid #e8e8e8;
  width: 100%;
  padding: 0 5px;
  height: calc(100% - 75px);
  overflow: auto;
}
.main-content-tree-area{
  
    border: 1px solid #e8e8e8;
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
}
.main-content-table-area{
  
    border: 1px solid #e8e8e8;
    width: 100%;
    padding: 0 5px;
    height: calc(100% - 75px);
}


.main-content-row-fooler{
    width: 100%;
}



.dis-between{
    display: flex;
    justify-content: space-between;
}
</style>